import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { media } from "utils/Media";

const StyledGalaContent = styled.section`
  width: 100%;
  .content {
    padding: 3rem 0;
    width: 90%;
    margin: 0 auto;
    @media ${media.md} {
      padding: 5rem 0;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      max-width: 1300px;
      margin: 0 auto;
      @media ${media.sm} {
        grid-template-columns: repeat(2, 1fr);
      }
      @media ${media.md} {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
`;
const SingleLotStyles = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  cursor: pointer;
  h6 {
    font-weight: 800;
    padding: 8px 0 0 0;
    font-size: 16px;
    text-transform: none!important;
    @media ${media.md} {
      font-size: 17px;
    }
  }
`;
const ImageWrapper = styled.div`
  padding: 6px;
  position: relative;

  &:before,
  &:after {
    content: "";
    box-sizing: inherit;
    position: absolute;
    border: 1px solid transparent;
  }

  &:after {
    bottom: 0;
    left: 0;
    border-left-color: #ff0000;
    border-top-color: #ff0000;
    width: 100%;
    height: 100%;
  }

  &:before {
    top: 0;
    right: 0;
    border-right-color: #ff0000;
    border-bottom-color: #ff0000;
    width: 100%;
    height: 100%;
  }

  .inner-shape {
    background: linear-gradient(
      190deg,
      #ff0000 17.93%,
      #ff7503 38.01%,
      #ffd200 60.03%
    );
    background-size: 100% 200%;
    padding: 12px;
    transition: all 0.4s ease;

    &:before {
      content: "";
      position: absolute;
      background-color: white;
      width: 20%;
      max-width: 3.5rem;
      height: calc(100% + 8px);
      z-index: 3;
      right: 25%;
      top: -4px;
      transform: skewX(-20deg);
    }
  }
  .img {
    position: relative;
    .lot {
      position: absolute;
      top: 0;
      z-index: 99;
      left: 0;
      background: #ff0000;
      padding: 5px 15px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 900;
    }
  }

  .inner-bg {
    position: absolute;
    background-color: white;
    z-index: 4;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 10px;
    left: 10px;
  }

  .gatsby-image-wrapper {
    position: relative;
    z-index: 5;
  }
`;
const StyledModal = styled.div`
  width: 100%;
  background: rgba(0,0,0,.5);
  position: fixed;
  inset: 0;
  height: 100%;  
  z-index: 999;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  section {
    width: 90%;
    margin: 2rem auto;
    overflow-y: hidden;
    max-width: 1100px;
    background: rgb(255, 0, 0);
    background: linear-gradient(
      69deg,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 117, 3, 1) 50%,
      rgba(255, 210, 0, 1) 100%
    );
    padding: 5px;
    @media ${media.md} {
      margin: 4rem auto 0 auto;
    }
    > div {
      padding: 1rem;
      background: #d9d9d9;
      position: relative;
      @media ${media.md} {
        padding: 2.5rem;
      }
      h6 {
        font-weight: 800;
        font-size: 22px;
        margin-bottom: 1rem;
      }
      p {
        margin: 0 0 0.5rem 0;
        padding: 0;
      }
      span {
        position: absolute;
        cursor: pointer;
        top: 1rem;
        right: 1rem;
      }
    }
  }
`;

export default function NewGalaContent() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");
  const { lots } = useStaticQuery(graphql`
    query {
      lots: allCharityGalaJson {
        nodes {
          lot
          title
          content
          image {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
    }
  `);
  const data = lots.nodes;

  useEffect(() => {
    if (modalOpen) {
      document.documentElement.classList.add('nav-active');
    } else {
      document.documentElement.classList.remove('nav-active');
    }
  }, [modalOpen]);

  return (
    <>
      <StyledGalaContent>
        <div className="content">
          <div className="grid">
            {data.map((lot, i) => (
              <SingleLot
                data={lot}
                index={i}
                key={i}
                setModalOpen={setModalOpen}
                setModalData={setModalData}
              />
            ))}
          </div>
        </div>
      </StyledGalaContent>
      <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        modalData={modalData}
      />
    </>
  );
}

const SingleLot = ({ data, index, setModalOpen, setModalData }) => {
  const { lot, title, content, image } = data;
  const handleClick = () => {
    setModalData(data);
    setModalOpen(true);
  };
  return (
    <SingleLotStyles onClick={() => handleClick()}>
      <ImageWrapper>
        <div className="inner-shape">
          <div className="img">
            <GatsbyImage image={getImage(image)} alt={title} />
            <span className="lot">{lot}</span>
          </div>
          <div className="inner-bg"></div>
        </div>
      </ImageWrapper>
      <h6>{title}</h6>
    </SingleLotStyles>
  );
};

const Modal = ({ modalData, setModalOpen, modalOpen }) => {
  
  const { title, content } = modalData;

  return modalOpen ? (
    <StyledModal onClick={() => setModalOpen(false)}>
      <section>
        <div>
          <h6>{title}</h6>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <span onClick={() => setModalOpen(false)}>&#x2715;</span>
        </div>
      </section>
    </StyledModal>
  ) : (
    ""
  );
};
