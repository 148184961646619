import React from "react"
import Layout from "../../components/Layout/LayoutNewYork"
import PageHeader from "../../components/shared/PageHeader"
import NewGalaContent from "../../components/new-york/GalaPage/NewGalaContent"
import { Helmet } from "react-helmet"

const AboutPage = () => {
  return(
    <Layout
      title="AUCTION GALA | New York | Back to the Future the Musical"
      description="Take an electrifying ride back in time with Back to the Future The Musical, Now playing on Broadway at the Winter Garden Theatre in New York."
      booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
    >
      <Helmet>
            <script
              id="satisfiScript"
              src="https://chat.satis.fi/popup/embedder?popupId=17903"
            ></script>
          </Helmet>
      <PageHeader city="new-york" title="AUCTION GALA" />

      <NewGalaContent/>
      
    </Layout>
  )
}

export default AboutPage
